function gaParseProduct(dom) {
    var jsonStr = $(dom).find(".jsonProdotto input").val();
    if (jsonStr) {
        return JSON.parse(jsonStr);
    }
    return null;
}

function gaParseProductList(dom) {
    var jsonStr = $(dom).find("input").val();
    return JSON.parse(jsonStr);
}

function gaFindProduct(dom) {
    if (dom) {
        return $(dom).find(".prodotto");
    }
    return $(".prodotto");
}

function gaFindProductList(dom) {
    if (dom) {
        return $(dom).find(".JsonInfoProdList");
    }
    return $(".JsonInfoProdList");
}

function gaProductImpressions(dom) {
    return $.map(gaFindProductList(dom), function (dom, index) {
        var productListObj = gaParseProductList(dom)
        if (productListObj == null) { return; }
        if (productListObj.ERROR != null) { return; }
        productListObj.event = 'ProductListImpressions';
        dataLayer.push(productListObj);
    });
}

function gaProductClicks(dom) {
    return $.map(gaFindProduct(dom), function (dom, index) {
             
        var productObj = gaParseProduct(dom);
        if (productObj == null) { return; }
        if (productObj.ERROR != null) { return; }

        var link = $(dom).find("[prodLink='true']");
        link.unbind();
        link.click(function (e) {
            e.preventDefault();
            var self = $(this);
            var redirectFunction = function () {
                var href = self.attr("href");
                document.location = href ? href : '/_s' + productObj.id + '.aspx';
            }

            if (!window.google_tag_manager) {
                redirectFunction();
                return;
            }

            productObj.eventCallback = redirectFunction
            productObj.eventTimeout = 2000
            dataLayer.push(productObj);
        });
        return productObj;
    });
}



