function onMouseOver(oggetto, nomeClasse, remClasse) {
    oggetto.removeClass(remClasse);
    oggetto.addClass(nomeClasse);
}

function onMouseOut(oggetto, nomeClasse, remClasse) {
    oggetto.removeClass(remClasse);
    oggetto.addClass(nomeClasse);
}


(function checkIE() {

    var user_agent = navigator.userAgent;

    var browser = user_agent.match(/NET/) || user_agent.match(/MSIE 9/) || user_agent.match(/MSIE 10/) || user_agent.match(/MSIE 11/) || user_agent.match(/MSIE\s\d/) ? 'explorer' : 'newbrowser';

    switch (browser) {
        case 'explorer':
            $('html').addClass('explorer');
            break;
        default:
            break;
    }

})();

