function autocompleteStreet(response, nation, city, street, numMinDigits) {

    if (street.replace(/'/gi, "\\'").length < numMinDigits) return;

    $.ajax({

        url: "/Autocomplete.aspx/getAutoCompleteStreet",
        data: " { 'regAddress': {'CountryCode' :'', 'Country' :'" + nation + "', 'DepartmentCode' :'', 'Department' :'', 'CityCode' :'', 'City' :'" + city + "', 'StreetCode' :'', 'Street' :'" + street + "', 'HouseNumCode' :'', 'HouseNum' :'', 'ZipCode' :'' } }",
        dataType: "json",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        success: function (data) {
            response($.map(data.d, function (item) {
                return { key: item, value: item.Street }
            }))
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {

        }
    });
}


function autocompleteCity(response, nation, city ,  numMinDigits) {

    if (city.replace(/'/gi, "\\'").length < numMinDigits) return;

    $.ajax({

        url: "/Autocomplete.aspx/getAutoCompleteCity",
        data: "{ 'regAddress': {'CountryCode' :'', 'Country' :'" + nation + "', 'DepartmentCode' :'', 'Department' :'', 'CityCode' :'', 'City' :'" + city + "', 'StreetCode' :'', 'Street' :'', 'HouseNumCode' :'', 'HouseNum' :'', 'ZipCode' :'' } }",
        dataType: "json",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        success: function (data) {
            response($.map(data.d, function (item) {
                return { key: item, value: item.City }
            }))
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {

        }
    });
}

function completeZipCode(nation, streetCode, houseNumber, zipCodeSelector) {

    if (houseNumber == undefined) houseNumber = "";

    $.ajax({

        url: "/Autocomplete.aspx/getAutocompleteZipCode",
        data: "{ 'regAddress': {'CountryCode' :'', 'Country' :'" + nation + "', 'DepartmentCode' :'', 'Department' :'', 'CityCode' :'', 'City' :'', 'StreetCode' :'" + streetCode + "', 'Street' :'', 'HouseNumCode' :'', 'HouseNum' :'" + houseNumber + "', 'ZipCode' :'' } }",
        dataType: "json",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        success: function (data) {
            if (data.d.length == 1) {
                var locationList = data.d[0];
                //completamento solo se il risultato è univoco
                setEgonValue(zipCodeSelector, locationList.ZipCode, locationList.ZipCode, true, false);
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {

        }
    });
}


function completeLocationFromZipCode(nation, zipCode, locationCitySelector, locationDepartmentSelector, numMinDigits) {

    if (zipCode.replace(/'/gi, "\\'").length < numMinDigits) return;

    $.ajax({

        url: "/Autocomplete.aspx/getAutoCompleteCity",
        data: "{ 'regAddress': {'CountryCode' :'', 'Country' :'" + nation + "', 'DepartmentCode' :'', 'Department' :'', 'CityCode' :'', 'City' :'', 'StreetCode' :'', 'Street' :'', 'HouseNumCode' :'', 'HouseNum' :'', 'ZipCode' :'" + zipCode + "' } }",
        dataType: "json",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        success: function (data) {
            if (data.d.length == 1) {
                var locationList = data.d[0];
                //completamento solo se il risultato è univoco
                setEgonValue(locationDepartmentSelector, locationList.DepartmentCode, locationList.Department, true, true);
                setEgonValue(locationCitySelector, locationList.CityCode, locationList.City, true, false);
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {

        }
    });
}




/*
Setta il codice (EGON) dell'indirizzo trovato tramite l'autocomplete
*/
function setEgonValue(selector, egonCode, egonDesc, setValue, isSelectHTMLSelector) {

    if (setValue!= undefined && setValue!=null && setValue == true) {
        $(selector).attr("value", egonDesc);
        $(selector).val(egonDesc);
    }
    $(selector).attr("egonCode", egonCode);
    $(selector).attr("egonDesc", egonDesc);
}


//impostazione autocomplete campi registrazione

function setAutocompleteToField(useAddressControl)
{

    if (useAddressControl) {
        $(".ADDRESS input").autocomplete({
            source: function (request, response) {
                if ($(".NATION select option[selected='selected']").attr("value") == "IT")
                    autocompleteStreet(response, $(".NATION select option[selected='selected']").text(), $('.CITY input').attr('value'), request.term, 3);
            },
            select: function (e, ui) {
                setEgonValue(".ADDRESS input", ui.item.key.StreetCode, ui.item.key.Street, true, false);
                completeZipCode($(".NATION select option[selected='selected']").text(), $('.ADDRESS input').attr('egoncode'), $('.NUMCIVICO input').attr('value'), ".CAP input")
            }
        });


        $(".ALTADDRESS input").autocomplete({
            source: function (request, response) {
                if ($(".ALTNATION select option[selected='selected']").attr("value") == "IT")
                    return autocompleteStreet(response, $(".ALTNATION select option[selected='selected']").text(), $('.ALTCITY input').attr('value'), request.term, 3);
            },
            select: function (e, ui) {
                setEgonValue(".ALTADDRESS input", ui.item.key.StreetCode, ui.item.key.Street, true, false);
                completeZipCode($(".ALTNATION select option[selected='selected']").text(), $('.ALTADDRESS input').attr('egoncode'), $('.ALTNUMCIVICO input').attr('value'), ".ALTCAP input")
            }
        });

        $(".NUMCIVICO input").autocomplete({
            source: function (request, response) {
                if ($(".NATION select option[selected='selected']").attr("value") == "IT")
                    completeZipCode($(".NATION select option[selected='selected']").text(), $('.ADDRESS input').attr('egoncode'), $('.NUMCIVICO input').attr('value'), ".CAP input")
            },
        });

        $(".ALTNUMCIVICO input").autocomplete({
            source: function (request, response) {
                if ($(".NATION select option[selected='selected']").attr("value") == "IT")
                    completeZipCode($(".NATION select option[selected='selected']").text(), $('.ALTADDRESS input').attr('egoncode'), $('.ALTNUMCIVICO input').attr('value'), ".ALTCAP input")
            },
        });

        $(".ALTCITY input").autocomplete({
            source: function (request, response) {
                if ($(".ALTNATION select option[selected='selected']").attr("value") == "IT")
                    autocompleteCity(response, $(".ALTNATION select option[selected='selected']").text(), request.term, 3);

            },
            select: function (e, ui) {

                setEgonValue(".ALTPROV select", ui.item.key.DepartmentCode, ui.item.key.Department, true, true);
                setEgonValue(".ALTCITY input", ui.item.key.CityCode, ui.item.key.City, true, false);

            }
        });

        $(".CITY input").autocomplete({
            source: function (request, response) {
                if ($(".NATION select option[selected='selected']").attr("value") == "IT")
                    autocompleteCity(response, $(".NATION select option[selected='selected']").text(), request.term, 3);

            }
            ,
            select: function (e, ui) {

                setEgonValue(".PROV select", ui.item.key.DepartmentCode, ui.item.key.Department, true, true);
                setEgonValue(".CITY input", ui.item.key.CityCode, ui.item.key.City, true, false);
            }
        });

        $(".CAP input").autocomplete({
            source: function (request, response) {
                if ($(".NATION select option[selected='selected']").attr("value") == "IT") {
                    completeLocationFromZipCode($(".NATION select option[selected='selected']").text(), request.term, ".CITY input", ".PROV select", 5);
                }
            }
        });

        $(".ALTCAP input").autocomplete({
            source: function (request, response) {
                if ($(".ALTNATION select option[selected='selected']").attr("value") == "IT") {
                    completeLocationFromZipCode($(".ALTNATION select option[selected='selected']").text(), request.term, ".ALTCITY input", ".ALTPROV select", 5);
                }

            }
        });
    }
}

function ShowConfirmDialog() {
    var r = confirm("l'indirizzo inserito non corrisponde al nostro database: confermi comunque i dati inseriti?");
    if (r == true) {
        $("[id*='DoSaveHiddenSubmit']").get(0).click();
    } else {
    }
}

function ResetAddressBorderColorOnChange() {
    $(".CAP").bind('input', function () {
        SetBorderColorForAddress('');
    });
    $(".CITY").bind('input', function () {
        SetBorderColorForAddress('');
    });
    $(".PROV select").change(function () {
        SetBorderColorForAddress('');
    });
    $(".ADDRESS").bind('input', function () {
        SetBorderColorForAddress('');
    });
    $(".NUMCIVICO").bind('input', function () {
        SetBorderColorForAddress('');
    });
}

function ResetAltAddressBorderColorOnChange() {
    $(".ALTCAP").bind('input', function () {
        SetBorderColorForAltAddress('');
    });
    $(".ALTCITY").bind('input', function () {
        SetBorderColorForAltAddress('');
    });
    $(".ALTPROV select").change(function () {
        SetBorderColorForAltAddress('');
    });
    $(".ALTADDRESS").bind('input', function () {
        SetBorderColorForAltAddress('');
    });
    $(".ALTNUMCIVICO").bind('input', function () {
        SetBorderColorForAltAddress('');
    });
}

function SetBorderColorForAddress(color) {
    $(".CAP input").get(0).style.borderColor = color;
    $(".CITY input").get(0).style.borderColor = color;
    $(".PROV select").get(0).style.borderColor = color;
    $(".ADDRESS input").get(0).style.borderColor = color;
    $(".NUMCIVICO input").get(0).style.borderColor = color;
}

function SetBorderColorForAltAddress(color) {
    $(".ALTCAP input").get(0).style.borderColor = color;
    $(".ALTCITY input").get(0).style.borderColor = color;
    $(".ALTPROV select").get(0).style.borderColor = color;
    $(".ALTADDRESS input").get(0).style.borderColor = color;
    $(".ALTNUMCIVICO input").get(0).style.borderColor = color;
}