function addEventWidgetBk(){

    var step = 215;
        var scrollstep = step + 1;
        var elemnumber = 100;
        var nelemnumber = 0;
        $('#sliderBk_previouscolumn').hide();
        var offset = $('#sliderBk_tablecontainer').width()
        //in caso di shopproductlist
        if ($('#sliderBk_tablecontainer .sliding-window > div > div > div > table') != undefined) {
            elemnumber = $('#sliderBk_tablecontainer .sliding-window > div > div > div > table > tbody > tr').children("td").size();
            nelemnumber = $('#sliderBk_tablecontainer .sliding-window > div > div > div > table > tbody > tr').children("td.empty").size();
            step = $('#sliderBk_tablecontainer .sliding-window > div > div > div > table > tbody > tr > td:first').width();
            scrollstep = step + 1;

            var max = -1;
            $("#sliderBk_tablecontainer .sliding-window > div > div > div > table > tbody > tr > td").each(function() {
                var h = $(this).height();
                max = h > max ? h : max;
            });
            $('#sliderBk_tablecontainer').css("height", max - 10)
            $('#sliderBk_tablecontainer .sliding-window').css("height", max)
        }

        var visual = (elemnumber - nelemnumber) * step;
        if (visual <= $('#sliderBk_upsOfferte').width()) {
            $('#sliderBk_nextcolumn').hide();
            $('#sliderBk_previouscolumn').hide();
        }

        $('#sliderBk_nextcolumn').click(function(event) {
            event.preventDefault();
            $('#sliderBk_tablecontainer').animate({ scrollLeft: '+=' + scrollstep }, 'fast', function() {
                if ($('#sliderBk_tablecontainer').scrollLeft() > 0) {
                    $('#sliderBk_previouscolumn').fadeIn();
                }
                if ($('#sliderBk_tablecontainer').scrollLeft() + offset >= visual - (step/2)) {
                    $('#sliderBk_nextcolumn').fadeOut();
                }
            });
        });
        $('#sliderBk_previouscolumn').click(function(event) {
            event.preventDefault();
            $('#sliderBk_tablecontainer').animate({ scrollLeft: '-=' + scrollstep }, 'fast', function() {
                if ($('#sliderBk_tablecontainer').scrollLeft() < visual) {
                    $('#sliderBk_nextcolumn').fadeIn();
                }

                if ($('#sliderBk_tablecontainer').scrollLeft() == 0) {
                    $('#sliderBk_previouscolumn').fadeOut();
                }
            });
        });
        $('#ctl00_Contenuto_sliderofferte_upcontent').ready(function() {
            var count = $('#sliderBk_upcontent').children().length;
            if (count == 0) {
                $('#sliderBk_upsOfferte').hide();
                $('#sliderBk_upsOfferte').parents('div.sliderbox').hide();
                $('#sliderBk_titlebox').hide();
            }
            else {
                if ($('#sliderBk_tablecontainer .sliding-window > div > div > div > table > tbody > tr > td:first').position().left == 0) {
                    $('#sliderBk_previouscolumn').fadeOut();
                }
            }

        });

}//addEventWidgetBk



function generateQueryStringCookie(bkNumber){

        if(bkNumber!= null && bkNumber!= undefined)
        {
             var nameCookieBk='_bklvp_'+bkNumber;
             var cookieBK=readCookie('_bklvp_'+bkNumber);
             if(cookieBK==null ||cookieBK==undefined)
             {  
                cookieBK='';
             }
            return "&_bklvp=" + cookieBK+ "&_bklvpName="+nameCookieBk;
        }else{
            return " "; 
        }
}//generateQueryStringCookie


function addLogoBK(idDivBk){
   $('<div class="logoBK" >  <img  src="/Images_net/logo_img_blueknow.png" > <p>Powered by BlueKnow </p> </div>').insertAfter("#sliderBk_slider");
}//addLogoBK


function setHeightWidget(listProd){
var maxHeight=0; 
var maxHeightImg=parseInt($(listProd + ' .immagine img').css('max-height'));
    $(listProd).each(function(e){
        var currentHeight=0;
        if($(this).find('img').height()==0)
            currentHeight=$(this).height()+maxHeightImg;
        else
            currentHeight=$(this).height();
            
        if(maxHeight< currentHeight){
            if($(this).find('img').height()==0)
                maxHeight=currentHeight; 
        }
     } );
     
     if(maxHeight!=0)
     {
        $(listProd).parents('.table-container').height(maxHeight+30);
        $(listProd).parents('.sliding-window').height(maxHeight+30);
     }
}//setHeightWidget


function blueKnowRequest(typeRequest,addbklvp, idDivBK, parameter, currentPage, typeRequestBK){
     var urlRequest="";
    if (readCookie('_bkaui') != null && typeRequest!=null && typeRequest!=undefined && typeRequest!="" && idDivBK!=null && idDivBK!=undefined && idDivBK!="" ) {
        urlRequest="/bk.aspx?";
        
        if(typeRequest=="item2item")
        {
            if(parameter!=null && parameter!=undefined  && parameter != "" && /[0-9]+/.test(parameter))
                urlRequest=urlRequest + "IdProdBk=" + parameter+ "&";
            else
                return;
         }//if
        if(typeRequest=="item2category" || typeRequest=="item2basket" || typeRequest=="item2search")
        {
            var nameParameters="";
            if(typeRequest=="item2category")
                nameParameters="idCatBk";
            if(typeRequest=="item2basket" || typeRequest=="item2search")
                nameParameters="listIdProdBk";
                
            if( parameter!=null && parameter!=undefined  && parameter != "")
                urlRequest=urlRequest + nameParameters +"="+ parameter+ "&";
            else
                return;
         }//if
         
        urlRequest= urlRequest + "typeRequestBk="+typeRequest + "&_bkaui=" + readCookie('_bkaui');
        if(addbklvp!=null && addbklvp!=undefined && addbklvp && readCookie('_bklvp')!=null )
        {
            urlRequest=urlRequest+"&_bklvp=" + readCookie('_bklvp');
        }else if(addbklvp!=null && addbklvp!=undefined && addbklvp && readCookie('_bklvp')==null )
        {
            return;
        }
        else
        {
            if(readCookie('_bklvp')!=null)
                urlRequest=urlRequest+"&_bklvp=" + readCookie('_bklvp');
        }
            
        if (readCookie('_bkuid')!=null)
            urlRequest=urlRequest+"&_bkuid=" + readCookie('_bkuid');
                
        $.ajax({
        url: urlRequest,
            success: function(result) {
                if(idDivBK=="bkCarrelloWidget")
                {
                    $('<section id="bkCarrelloWidget" class="bkSection" style="margin-bottom: 15px; margin-top: 20px;"></section>').insertAfter('.ShoppingCart .subtotale');
                }
                $('#' + idDivBK).html($(result).find('.sliderbox'));
                //addLogoBK('#bkCarrelloWidget');
                $('div#divBkWidget img').each(function(e) {
                    var src = $(this).attr('src');
                    if (/^[\/]/.test(src) == false && /^http/.test(src) == false) 
                        $(this).attr('src', '/' + src);
                });

                addEventWidgetBk();
                setHeightWidget("#sliderBk_tablecontainer .prodotto");
                if(idDivBK=="bkSchedaWidget")
                {
                    var src = $('#sliderBk_slider .info.novita.medium .novitamini img').attr('src');
                    src = "/" + src;
                    if (/^[\/]/.test(src) == false && /^http/.test(src) == false) 
                        $('#sliderBk_slider .info.novita.medium .novitamini img').attr('src', src);
                }//if
                
                
                gaProductClicks($("#divBkWidget"));
                gaProductImpressions($("#divBkWidget"));
                trackClicked(typeRequestBK, currentPage);
                
            }//success
        });
    }//if
}//blueKnowRequest


function trackClicked(restCall, page){

    if(restCall!=null && restCall!=undefined && restCall!="" && page!=null && page!=undefined && page!="")
    {
        var bkNumber=$('.bkNumber').text().trim();
     
         if(bkNumber!=null && bkNumber!=undefined && bkNumber!="")   
         {
                var tracker = Blueknow.getTracker(bkNumber);
                tracker.setCurrency("EUR");
                tracker.setLanguage("it");
             
               $("#sliderBk_tablecontainer .prodotto a").click(function () 
                        {
                            var uriProd=$(this).attr('href');
                            var match = uriProd.match(/s(\d+)\.aspx.*/);
                            if(match!=null && match!=undefined)
                            {
                                var idprod=match[1];
                                return tracker.trackClicked(idprod,restCall,page);
                            }//if
                });
          }//if
   }//if

}//trackClicked