function restylePaginazione()
{
    if($('.ShopProductList .prodotti .paginazione .indietro').height() != null)
    $('.ShopProductList .prodotti .paginazione .indietro').html('<img src="/Images_net/arrow_pre.png"/> Indietro');
    
    if($('.ShopProductList .prodotti .paginazione .avanti').height() != null)
    $('.ShopProductList .prodotti .paginazione .avanti').html('Avanti <img src="/Images_net/arrow_next.png"/>');
}

function showWaitingBox(containerCssClass, absolute)
{
    if (typeof(absolute) == "undefined")
        absolute = true;
    var container = $(containerCssClass);          
    if(container != null && container.length > 0)
    { 
        var container_height = container.height();
        //$(".waiting-bg").css({'margin-top': ((-1) * container_height) + 'px' });
        //var left = (container.width() - $(".waiting_content img").width())/2;  
        //$(".waiting_content").css('margin-left', left + 'px');
        if (absolute == true) {
            $(".waiting-bg").css({
                "position":"absolute",
                "top":container.offset().top+"px",
                "left":container.offset().left+"px", 
                "width":container.width()+"px",
                "height":container.height()+"px"
            });
        } else {
            $(".waiting-bg").css({
                "position":"absolute",
                "top":container.position().top+"px",
                "left":container.position().left+"px", 
                "width":container.width()+"px",
                "height":container.height()+"px"
            });
        }
    }
    return false;
}

function openNewsletterDialog() {
//    $(document).scrollTop(0);
//    ShowOverLay('#overlay-nl-bg', '#overlay-nl-cover','#nlpanel', null , null); 
    $(location).attr("href","/promozione/newsletter.aspx");
}
       
/* End Login Overlay functions */    
function ShowOverLay(bgId,CoverId, ContentId, top, left)
{
    var docHeight = $(document).height(); //grab the height of the page        
    $(bgId).show().css({'height' : docHeight}); //display your popup and set height to the page height 
    $(CoverId).css({'height' : docHeight})
    if(top == null) top =   ($(window).height() - $(ContentId).outerHeight())/2;   /* if top null show overlay on middle */  
    if(left == null) left  = ($(window).width() - $(ContentId).outerWidth())/2 ;   /* if top null show overlay on center */
    $(ContentId).css({'top': top + 'px', 'left' : left + 'px'}); //set the content show on center page
    return false;  
}

/* Hide Overlay when click outsite*/
function HideOverlay(e, bgId, contentId)
{       
     if(!(contentId === e.target.id) &&  $(contentId).has(e.target).length === 0)
     {
        $(bgId).hide(); 
     }
     return false;   
}

/* Hide Overlay */
function CloseOverlay(bgId)
{ 
     $(bgId).hide();         
     return false;  
}

function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name,"",-1);
}

function addNoBuyOptionBox()
{
    $('.sfondoLineaNew div.prodotto div.info ').each(function () {
       var pricebox =  $(this).find('div.opzioneprincipale');
       
       if($(pricebox).length <= 0)
       {
            $(this).append('<div class="opzioneprincipale" style="cursor: pointer;"><div style="position:absolute; margin-top:20px; font-size:18px; color:#cce6f3; text-align:center; width:160px;">Il prodotto non &egrave <br/> acquistabile su <br/> ShopWKI</div></div>');
       } 
       else if($.trim($(pricebox).text()) === '')
       {
            //$(pricebox).empty();
            //$(pricebox).append('<div style="position:absolute; margin-top:20px; font-size:18px; color:#cce6f3; text-align:center; width:160px;">Scegli lo store e scarica gratis!</div>');
       }            
    }); 
}

function pricehandle(targetBlank)
{
    $('.sfondoLineaNew .opzioneprincipale').css("cursor", "pointer").each(function () {
        var url = $(this).parents('div.prodotto').find('.immagine a').attr("href");
        if (targetBlank == true)
            $(this).wrap("<a prodLink='true' href='"+url+"' target='_blank'></a>");
        else
            $(this).wrap("<a prodLink='true' href='" + url + "'></a>");
    });
}

function adjustPriceBox()
{
    $('.sfondoLineaNew div.prodotto div.info').each(function () {

        var dettagli = $(this).find('div.opzioneprincipale div.dettagli');
        
        var hasDiscount = false;
        if($(this).find('div.opzioneprincipale div.dettagli span.pricebox div span.prezzoPieno').height() != null)
            hasDiscount= true;             

        var hasotheroptions = false;   
        if($(this).find('div.otheroptions').height() != null)
            hasotheroptions = true;
            
        var prezzofinale = null;        
        if(hasDiscount)
            prezzofinale = $(this).find('div.opzioneprincipale div.dettagli span.pricebox div span span.prezzofinale');     
        else             
            prezzofinale = $(this).find('div.opzioneprincipale div.dettagli span.pricebox div span.prezzofinale');               
       
        var hasIVA = false;
        if( prezzofinale.parent().find('span.iva').height() != null)
            hasIVA = true;
      
        if(hasIVA )
        {  
            if(hasotheroptions )
                $(this).find('div.otheroptions').offset().top = $(this).find('div.otheroptions').offset().top + 10;
            if(!hasDiscount)
            {
                prezzofinale.parent().find('span.iva').attr('style','font-size : 12px !important; font-weight : bold !important');
            }
        }
        if(hasDiscount)
        {
            prezzofinale.html('<div  style="text-align : center; ">'  + prezzofinale.html()+ '</div>');                
        }
        else
        { 
            prezzofinale.parent().find('span.iva').css('margin-top', '0px');                         
        }  
        
        if(!hasotheroptions)
        {
            $(dettagli).css('margin-top', '35px');
        }  
        else
        {
            if(!hasDiscount)
            {
                if(!hasIVA)
                {
                    $(dettagli).css('margin-top', '35px');
                }
                else              
                $(dettagli).css('margin-top', '25px');
             }             
             else 
             {
                  if(!hasIVA)
                        $(dettagli).css('margin-top', '15px');
                  else   $(dettagli).css('margin-top', '20px');
              }
         }  
    });
}

function addAbstractShowMore()
{
    var moretext = "<strong>Mostra tutto</strong>...<img src='../Images_net/triangolo.jpg'></img>";
    var lesstext = "<strong>Mostra meno</strong>...<img src='../Images_net/less_text.png'></img>";            
    var trigger = "<a href='javascript:void(0)' class='espandi_scheda_speciale'></a>";
    
    $('.sfondoLineaNew div.abstract').each(function() {
        var self = $(this);
        if ($.trim(self.html()) != '')
            self.wrapInner("<p></p>");
    });
    
    $('.sfondoLineaNew div.prodotto').each(function() {
      if($(this).find('div.promo').length <= 0)
      {
        $(this).append('<div class="promo" style="padding: 1px;"></div>');
      }
    });
    
    $('.sfondoLineaNew div.abstract').each(function () {
        
        var self = $(this).find('p').first();
        
        var absLineHeight = 18;
        var fontsize = 13;
        var info = self.parent().parent();
        var abstrac = self.parent();
        var prodottoDiv = info.parent();                
        var titoloH = info.find('div.titolo').height(); 
        var origHeight = self.height() - absLineHeight;
                 
        var promoDiv =  prodottoDiv.find("div.promo");              
        promoDiv.css({ "padding-top": (titoloH + 5) + "px"});
        abstrac.css({"height" :2 * fontsize + "px"});
        self.css("padding-bottom", "13px"); 
        if (origHeight / absLineHeight > 3) 
        {
            self.css({"height": 2 * fontsize + "px", "overflow":"hidden"});          
            abstrac.append($(trigger).html(moretext).click(function () 
            { 
                if ($(this).hasClass("less")) 
                {                  
                    $(this).removeClass("less");                        
                    self.animate({ height: 2*fontsize + 'px' }, 180);                         
                    promoDiv.animate({ "padding-top": (titoloH + fontsize) + "px"}, 180);                                              
                    $(this).html(moretext); 
                } 
                else 
                { 
                    $(this).addClass("less");
                    self.animate({ height: origHeight +"px" }, 180);                         
                    promoDiv.animate({ "padding-top": (titoloH + origHeight-fontsize) +"px"}, 180);                                                  
                    $(this).html(lesstext);   
                }
            }));
        }				
    });
} 

function adjustProductInfoStyle()
{
    adjustPriceBox();
    
    $('.sfondoLineaNew div .autori').filter(function(){return $.trim($(this).text()) === '';}).hide();
    $('.sfondoLineaNew div .autori').css({ 'padding': '0', 'margin': '0'});
    $('.sfondoLineaNew div .autori .singolatipologiaautori').css({ 'padding-left': '2px'});
    $('.sfondoLineaNew div .autori .singolatipologiaautori').each(function(i){
        if(i>0)
            $(this).css({ 'margin-top': '0'});
    });
    
    $('.sfondoLineaNew .boundle .prodotti .prodotto .info .singoloAutore').filter(function(){
        return $(this).text() === ''}).hide();
    
    $('.sfondoLineaNew .prodotti .prodotto .info').each(function(){
        var height = $(this).find('.novitamini').height() + $(this).find('.titolo').height()
            + $(this).find('.abstract').height() 
            + $(this).find('.editore').height() + $(this).find('.anno').height();                    
        //$(this).children().each(function(){ height += $(this).height();});
        var autori = $(this).find('.autori').height();
        if(autori == 0)
        {
            $(this).find('.autori .singolatipologiaautori').each(function (){
                autori += $(this).height();
            });
        }
        height += autori;
        height -= 5;
        $(this).css('min-height', height + 'px');
    });
}   

    /*
    Modifica l'attributo <attrToModify> del tag estrepolato del selettore <selectorToModify>  in base al valore dell'attr dello stesso tag che si trova nella <requestData>,
    Se non si trova il tag nella pagina corrente viene aggiunto tutto il tag della <requestData>
    Se non viene trovato il tag nella <requestData> viene eliminato dalla pagina corrente
    */
    function modifyOrAddTagFromRequest(selectorToModify, fatherSelector , attrToModify,  requestData)
    {
        if (selectorToModify == undefined || selectorToModify == null || selectorToModify.trim() == '') return;
        if (attrToModify == undefined || attrToModify == null || attrToModify.trim() == '') return;
        if (requestData == undefined || requestData == null || requestData.trim() == '') return;

        if ($(requestData).filter(selectorToModify) != null && $(requestData).filter(selectorToModify) != undefined && $(requestData).filter(selectorToModify).length > 0)
        {
            if ($(selectorToModify) != undefined && $(selectorToModify) != null && $(selectorToModify).length > 0)
            {
                $(selectorToModify).attr(attrToModify, $(requestData).filter(selectorToModify).attr(attrToModify));
            }
            else
            {
                if (fatherSelector == undefined || fatherSelector == null || fatherSelector.trim() == '') return;
                $(fatherSelector).append($(requestData).filter(selectorToModify));
            }

        } else
        {
            $(selectorToModify).remove();
        }
    }//modifyOrAddTagFromRequest
 

  function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}


